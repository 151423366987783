<template>
  <div>
    <PageHeader title="Bestellingen">
      <template #buttons>
        <router-link
          :to="{ name: 'Shop' }"
          title="Shop"
          class="btn btn-primary"
        >
          <i class="fas fa-shopping-cart" />
          Shop
        </router-link>
      </template>
    </PageHeader>
    <DataTable
      :loading="loading"
      :headers="headers"
      v-bind="orders"
      class="tw-my-8"
      @changePage="fetchOrders"
    >
      <template #item-id="{ item }">
        <router-link :to="{ name: 'ShopOrderDetails', params: { id: item.id } }">
          {{ item.reference }}
        </router-link>
      </template>
      <template #item-supplier="{ item }">
        <a :href="`mailto:${item.supplier.email}`" target="_blank">{{ item.supplier.name }}</a>
      </template>
      <template #item-created_on="{ item }">
        {{ formatDateNlBe(item.created_on) }}
      </template>
      <template #item-status="{ item }">
        <span
          :class="['label', item.confirmed ? 'label-primary' : 'label-warning']"
        >
          {{
            item.confirmed
            ? 'Bevestigd op ' + formatDateNlBe(item.confirmed_on, 'dd-MM-yyyy HH:mm')
            : 'In Afwachting'
          }}
        </span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatDateNlBe } from '@/utils/helpers'

import DataTable from '@/components/DataTable'
import PageHeader from '@/components/PageHeader.vue'

import { getOrders } from '@/services/shop'

export default {
  name: 'ShopOrders',
  components: {
    DataTable,
    PageHeader
  },
  data () {
    return {
      loading: false,
      // The object orders includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      orders: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'id', text: 'ID' },
        { value: 'supplier', text: 'Leverancier' },
        { value: 'created_on', text: 'Datum' },
        { value: 'status', text: 'Status' }
      ]
    }
  },
  created () {
    this.fetchOrders()
  },
  methods: {
    formatDateNlBe,
    async fetchOrders (payload) {
      try {
        this.loading = true
        const response = await getOrders(payload)
        this.orders = response.data
        this.loading = false
        return response
      } catch (error) {

      }
    }
  }
}
</script>
