var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Bestellingen"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'Shop' },"title":"Shop"}},[_c('i',{staticClass:"fas fa-shopping-cart"}),_vm._v(" Shop ")])]},proxy:true}])}),_c('DataTable',_vm._b({staticClass:"tw-my-8",attrs:{"loading":_vm.loading,"headers":_vm.headers},on:{"changePage":_vm.fetchOrders},scopedSlots:_vm._u([{key:"item-id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ShopOrderDetails', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.reference)+" ")])]}},{key:"item-supplier",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.supplier.email)),"target":"_blank"}},[_vm._v(_vm._s(item.supplier.name))])]}},{key:"item-created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNlBe(item.created_on))+" ")]}},{key:"item-status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['label', item.confirmed ? 'label-primary' : 'label-warning']},[_vm._v(" "+_vm._s(item.confirmed ? 'Bevestigd op ' + _vm.formatDateNlBe(item.confirmed_on, 'dd-MM-yyyy HH:mm') : 'In Afwachting')+" ")])]}}])},'DataTable',_vm.orders,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }